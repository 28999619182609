import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import { withTrans } from '../i18n/withTrans'

const Straipsniai = ({t, i18n, pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      wpcontent {
        posts(first: 10) {
          edges {
            node {
              id
              authorId
              date 
              link
              slug
              title
              excerpt
              content
              featuredImage {
                node {
                  id
                  sourceUrl
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 400
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="img_bg">
          <div className="container">
            <title>{pageTitle}</title>
            <header>{pageTitle}</header>
          </div>
          <br />
          <br />
          <div className="container blog-posts">
            {data.wpcontent?.posts?.edges?.map(edge => (
              <div className="blog-post-item item_box" key={edge.node.id}>
                

                {edge.node.featuredImage && (
                  <div
                    
                    key={edge.node.featuredImage.node.id}
                  >
                    <div className="item_boxes">
                      {edge.node.featuredImage?.node?.imageFile && (
                        <GatsbyImage className="image_min"
                          image={getImage(
                            edge.node.featuredImage.node.imageFile
                          )}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}
                
                <div className="blog_item_content cyan_link">
                  <div>{edge.node.date}</div>
                  <h2>{edge.node.title}</h2>
                  <p dangerouslySetInnerHTML={{ __html: edge.node.excerpt }}></p>
                  <a href={"/" + edge.node.slug}>{t('blog.read')}</a>
                </div>
                
              </div>
            ))}
          </div>
      </div>
    </>
  )
}

export default withTrans(Straipsniai)
